import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';

type PropTypes = {
  attributeList: string[];
  marginBottom?: number;
  css?: CSS;
};

function Attributes({ attributeList, css }: PropTypes) {
  return (
    <ProductAttributeList css={css}>
      {attributeList.map((attribute, index) => {
        return (
          <AttributeItem key={index}>
            <BodyText color="primary" size="l" weight="semiBold" tag="span">
              {attribute}
            </BodyText>

            {attributeList.length !== index + 1 && <Dot>&#8226;</Dot>}
          </AttributeItem>
        );
      })}
    </ProductAttributeList>
  );
}

const ProductAttributeList = styled('ul', {
  listStyle: 'initial',
  display: 'flex',
  listStyleType: 'none',
  gap: '$s50',
  mt: 2,
});

const AttributeItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  gap: '$s50',
  '& p': {
    textTransform: 'capitalize',
  },
  '& span': {
    color: '$onSurface',
  },
});

const Dot = styled('span', {
  position: 'relative',
  display: 'flex',
});

export default Attributes;
