import { useRef } from 'react';
import { styled } from 'Theme/stitches.config';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { VariantProvider } from 'Shared/Providers/VariantProvider';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import AttributeArea from './AttributeArea';
import useMedia from 'Shared/Hooks/useMedia';

import ProductAccordion from './ProductAccordion';

import {
  ImageScalingTypes,
  getResizedImageFromEgmontCDN,
} from 'Shared/Common/ResizeImageEgmontCDN';
import GiftCardProductPageModel from 'Models/Pages/ProductPage/GiftCardProductPageModel.interface';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { scrollToElementByRef } from 'Shared/Common/Helpers';

function GiftCardProductPage() {
  const productData = useCurrentPage<GiftCardProductPageModel>();
  const isDesktop = useMedia(mediaQueryTypes.bpMin961);
  const { missingImage } = useAppSettingsData().imageSettings;
  const accordionElementRef = useRef<null | HTMLDivElement>(null);
  const { contentArea } = productData;

  const renderProductAccordion = () => {
    return (
      <ProductAccordionWrapper ref={accordionElementRef}>
        <ProductAccordion items={productData.accordions} />
      </ProductAccordionWrapper>
    );
  };

  return (
    <VariantProvider>
      <ContentContainer>
        <Top>
          <AttributeArea
            openFirstAccordionItem={() =>
              scrollToElementByRef(accordionElementRef)
            }
            productPage={productData}
          />
          <ProductImageWrapper>
            <ProductImage
              src={
                productData.mainImage
                  ? getResizedImageFromEgmontCDN(
                      productData.mainImage.src,
                      ImageScalingTypes.SUBSCRIPTION_OFFER_PAGE
                    )
                  : missingImage.src
              }
              alt={productData.mainImage ? productData.mainImage.alt : ''}
            />
            {isDesktop && renderProductAccordion()}
          </ProductImageWrapper>
        </Top>
        {!isDesktop && renderProductAccordion()}
      </ContentContainer>

      {contentArea && contentArea.length > 0 && (
        <Section css={ContentSection}>
          <Bottom>
            {Array.isArray(contentArea) && (
              <ContentArea childItems={contentArea} />
            )}
          </Bottom>
        </Section>
      )}
    </VariantProvider>
  );
}

const Section = styled('section', {
  position: 'relative',
  maxW: '100%',
});

const Top = styled('div', {
  g: 0,
  w: '100%',

  '@bpMin721': {
    display: 'grid',
    gridTemplateColumns: '1.3fr 1fr',
    g: '$s200',
    mb: '$s500',
  },
  '@bpMax720': {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ProductAccordionWrapper = styled('div', {
  mb: 4,
  '@bpMin961': {
    mb: 12,
  },

  order: 2,
});

const ProductImageWrapper = styled('div', {
  position: 'relative',
  px: 0,
  w: '100%',
  mb: '$s150',
  '@bpMin961': {
    mb: 0,
  },
});

const ProductImage = styled('img', {
  width: '100%',
  objectFit: 'cover',
});

const ContentSection = {
  px: 0,
};

const Bottom = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pl: 0,
  h: 'auto',
  w: '100%',
  borderBlockStart: '10px',
});

export default GiftCardProductPage;
