import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'Theme/stitches.config';
import { ProductUSP } from './ProductUSP';
import ProductCTA from './ProductCTA';
import ProductTitle from './ProductTitle';
import {
  getUrlParameter,
  isEmpty,
  setUrlParameter,
} from 'Shared/Common/Helpers';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import { Variant } from 'Enums/Variant.enum';
import { useEffect, useState } from 'react';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import ProductBaseModel from 'Models/Pages/Base/ProductBaseModel.interface';

type PropTypes = {
  productPage: ProductBaseModel;
  openFirstAccordionItem?: () => void;
};

function AttributeArea({ productPage, openFirstAccordionItem }: PropTypes) {
  const [selectedVariant, setSelectedVariant] = useState<string>('');
  const {
    productLabels: { shortDescriptionReadMoreText, productUsps },
  } = useTranslationData();

  const { variationCollection } = productPage;

  const { variantDispatch } = useVariantData();

  useEffect(() => {
    const code = getUrlParameter('code', window.location.href);
    setSelectedVariant(code);
  }, []);

  useEffect(() => {
    const variant =
      variationCollection.filter(
        (variant) => variant.code === selectedVariant
      )[0] ??
      variationCollection[0] ??
      null;
    variantDispatch({
      type: Variant.SET_INITIAL,
      selectedVariant: variant,
      hasSizes: false,
    });
  }, [selectedVariant]);

  useEffect(() => {
    if (isEmpty(variationCollection)) return;

    const fallbackVariant = variationCollection[0];
    // get variant that is buyable, and is not price 0 and is the lowest price

    const defaultVariant = variationCollection
      .filter((variant) => variant.nowPrice.price !== 0 && variant.isBuyable)
      .reduce<null | VariationModel>((prev, curr) => {
        if (prev === null) return curr;

        return prev.nowPrice.price < curr.nowPrice.price ? prev : curr;
      }, null);

    variantDispatch({
      type: Variant.SET_INITIAL,
      selectedVariant: defaultVariant ?? fallbackVariant,
      hasSizes: false,
    });
  }, [variationCollection]);

  const onCollectionChange = (value: string) => {
    setSelectedVariant(value);
    setUrlParameter('code', value, true);
  };

  return (
    <AttributeContainer>
      <AttributeWrapper>
        <ProductTitle productPage={productPage} />
        {productPage.shortDescription && (
          <BodyText size="l">
            {productPage.shortDescription}
            {productPage.hasLongDescription && (
              <>
                {' '}
                <ReadMore
                  onClick={(e) => {
                    e.preventDefault();
                    if (openFirstAccordionItem) {
                      openFirstAccordionItem();
                    }
                  }}
                >
                  {shortDescriptionReadMoreText}
                </ReadMore>
              </>
            )}
          </BodyText>
        )}
        {variationCollection.length > 1 && (
          <Select
            value={selectedVariant}
            onChange={(event) => onCollectionChange(event.target.value)}
          >
            {productPage.variationCollection.map((variation, index: number) => (
              <option key={index} value={variation.code}>
                {variation.name}
              </option>
            ))}
          </Select>
        )}
        <ProductCTA />
        <ProductUSP
          productUsp={productPage.usp}
          globalUsp={productPage.globalUsp}
          label={productUsps}
        />
      </AttributeWrapper>
    </AttributeContainer>
  );
}

const AttributeContainer = styled('div', {
  order: 1,
});

const AttributeWrapper = styled('div', {
  w: '100%',
});

const Select = styled('select', {
  w: '100%',
  height: '48px',
  border: '1px solid $onSurfaceDisabled',
  borderRadius: '4px',
  padding: '4px',
  mb: '$s200',
  cursor: 'pointer',
  '&:focus-visible': {
    outline: '2px solid $interactiveBorderActive',
    outlineOffset: '-2px',
  },
});

const ReadMore = styled('a', {
  color: '$emphasisedLinkColor',
  fontFamily: 'fontSemiBold',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    cursor: 'pointer',
  },
});

export default AttributeArea;
