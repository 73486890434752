import Attributes from 'Commerce/Molecules/Attributes/Attributes';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import { styled } from 'Theme/stitches.config';
import ProductBaseModel from 'Models/Pages/Base/ProductBaseModel.interface';

type ProductTitleType = {
  productPage: ProductBaseModel;
  used?: string;
};

function ProductTitle({ productPage }: ProductTitleType) {
  const { displayName, age } = productPage;

  const attributes = (age && [age]) ?? [];

  return (
    <Wrapper>
      <Heading size={'l'} tag="h1" color="Regular">
        {displayName}
      </Heading>
      {attributes && attributes?.length > 0 && (
        <Attributes attributeList={attributes} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  mb: '$s100',
});

export default ProductTitle;
